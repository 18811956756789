import React, {Component, useEffect} from 'react';
// import React from "react";
import Carousel from 'react-bootstrap/Carousel';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Container} from "react-bootstrap";
// import background from "../../assets/img/home1.png";
import scroll from "../assets/logo/scroll.png";
// import {useTranslation} from "react-i18next";
import './i18n';
import Aos from 'aos';
import 'aos/dist/aos.css'
import { useTranslation } from "react-i18next";

const lngs = [
    { code: "en", native: "English" },
    { code: "pt", native: "Português" },
];


function  Home(){
    useEffect(()=>{
        Aos.init({ duration: 2000});
    },[]);
    const { t, i18n } = useTranslation();
    const handleTrans = (code) => {
        i18n.changeLanguage(code);
        console.log("chamada");
    };
// }
// class Home extends Component {

    // render() {
       function disableScroll() {
           var x = document.getElementById("id-menu");
           if (x.style.display === "block") {
               x.style.display = "none";
               // document.body.classList.add("stop-scrolling");
               document.body.classList.remove("stop-scrolling");
               document.getElementById('image-menu-x').style.display = "none";
               document.getElementById('principal').style.display = "block";

           }
           document.body.classList.remove("stop-scrolling");
            console.log("fora")
        }
        return (
            <div>
                <section id="id-menu" className="img-class new-class-menu" style={{ backgroundImage: `url(${require('../assets/img/MenuBackground.png')})` ,
                    backgroundRepeat:"no-repeat",
                    height:'100vh',
                    backgroundSize:"100% 100vh"

                }}>
                    <Container className="hide-class">
                        <Row>
                            <Col>
                                <ul>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                <li><h1 className="force-font"><a  onClick={() => { disableScroll(); }} href="#ocean">{t('concept')}</a></h1></li>
                                    <br/>
                                    <br/>
                                <li><h1><a href="#id-menu" onClick={() => { disableScroll(); }}>Menu</a></h1></li>
                                    <br/>
                                    <br/>
                                <li><h1><a href="#pesca" onClick={() => { disableScroll(); }}>{t('pescaSustentavel')}</a></h1></li>
                                    <br/>
                                    <br/>
                                <li><h1><a href="#contact" onClick={() => { disableScroll(); }}>{t('contact')}</a></h1></li>
                            </ul></Col>
                        </Row>
                    </Container>
                </section>
                <div className="limite">

                    {/*<div className="img-class" style={{ backgroundImage: `url(${require('../assets/img/ocean_main_banner.png')})` ,*/}
                    {/*backgroundRepeat:"no-repeat",*/}
                    {/*    height:'95vh',*/}
                    {/*    backgroundSize:"100% 95vh",*/}

                    {/*}}>*/}
                    <div className="img-class banner" >
                     <ul>
                         <li>
                             <img data-aos="zoom-out-down" className="mt-5 logo" src={require('../assets/logo/logo1.png')} alt=""/>
                         </li>
                         <li className="arrow"><a href="#ocean"><img src={scroll} width="39.09" alt=""/></a></li>
                     </ul>

                    </div>
                </div>
              <section id="ocean" className="ocean-section" data-aos="fade-up-left">
                  <Container>
                      <Row className="mt-5 mb-5">
                          <Col md={{ span: 8, offset: 2 }}>
                              <h1>Ocean</h1>
                              <p>
                                  <br/>

                                      {t('ocean')}

                                  {/*É um nome que absorve tudo, um substantivo que nos transporta por sabores adentro, um vocábulo que revela objectivos. <br/>*/}
                                  {/*É um caminho que aponta a direcção, um barco que navega com coordenadas certas, um projecto em busca de novas conquistas, o rosto de um novo olhar. <br/>*/}
                                  {/*É um restaurante que se dedica a promover a nossa costa e o que de melhor provém dela. Que eleva o país ao melhor de si próprio. Um novo ponto de vista sobre uma cidade, uma miragem sobre uma praia, várias vistas sobre o mar. <br/>*/}
                                  {/*É símbolo de vitalidade. Uma marca que é uma afirmação, que pretende fazer a diferença, com a segurança sempre a indicar o caminho. <br/>*/}
                                  {/*É relaxamento. Deixar-se levar pelos aromas e cores que nos invadem o palato e nos fazem vibrar com a explosão interna entre garfadas e brindes!*/}
                             <br/>
                             <br/>
                              </p>
                              <h3>
                                 {/* Ocean é  ode ao Oceano Índico*/}
                                 {/*<br/> na sua mesa*/}
                                  {t('indico')}
                              </h3>
                              <p className="vm"><u><a href="https://oceanmaputo.co.mz/Ocean_Menu_Web.pdf" target="_blank">VER MENU</a></u></p>
                          </Col>
                      </Row>

                  </Container>

              </section>
              <section id="carosel">
                  <Carousel variant="dark" data-aos="flip-left">
                      <Carousel.Item>
                          <img
                              className="slide-class d-block w-100"
                              src={require('../assets/slides/ocean_slider_01.png')}
                              alt="First slide"
                          />
                      </Carousel.Item>
                      <Carousel.Item>
                          <img
                              className="d-block w-100 slide-class"
                              src={require('../assets/slides/ocean_slider_02.png')}
                              alt="First slide"
                          />

                      </Carousel.Item>
                      <Carousel.Item>
                          <img
                              className="d-block w-100 slide-class"
                              src={require('../assets/slides/ocean_slider_03.png')}
                              alt="First slide"
                          />

                      </Carousel.Item>
                      <Carousel.Item>
                          <img
                              className="d-block w-100 slide-class"
                              src={require('../assets/slides/ocean_slider_04.png')}
                              alt="First slide"
                          />

                      </Carousel.Item>
                  </Carousel>
              </section>
                <section id="pesca" className="selection-section" >
                    <Container>
                        <Row className="mt-5 mb-5">
                            <Col md={{ span: 8, offset: 2 }}>
                                <img className="camara" src={require('../assets/img/ocean_pesca_sustentavel.png')} alt=""/>
                                <h3>     {t('careful')} </h3>
                                <p>
                                    <br/>
                                    {t('carefultext')}
                                    <br/>
                                    <br/>
                                </p>

                            </Col>
                        </Row>

                    </Container>

                </section>
            </div>
        );
    // }
}
export default Home;