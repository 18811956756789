// import React, {Component} from 'react';
import React, { useState, useEffect } from "react";
import {Navbar,Container} from "react-bootstrap";
import menu from "../assets/logo/menu.png";
import menux from "../assets/logo/menux.png";
// import ReactDOM from 'react-dom/client';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
// import $ from 'jQuery';
import $ from 'jquery';
import { Routes ,Route } from 'react-router-dom';
import {
    BrowserRouter as Router,
    Link
} from "react-router-dom";
import {useTranslation} from "react-i18next";
// import {click} from "@testing-library/user-event/dist/click";
// import Home from "./Home";
const lngs = [
    { code: "pt", native: "PT" },
    { code: "en", native: "EN" },
];
    export default function App() {

        $(document).ready(function() {
            // console.log( $('li a').removeClass("active"));
            $('button').click(function(){
                $('button').removeClass("active");
                $(this).addClass("active");
            });
        });
        const { t, i18n } = useTranslation();
        const handleTrans = (code) => {
            i18n.changeLanguage(code);
            console.log("chamada");
        };
        const changeLanguage = (lng) => {
            i18n.changeLanguage(lng);
        }
        // function disableScroll() {
        //     document.body.classList.add("stop-scrolling");
        // }
        // function enableScroll() {
        //     document.body.classList.remove("stop-scrolling");
        // }
        // const box = document.getElementById('principal');
        //
        // box.addEventListener('click', () => {
        //     box.remove();
        // });
        function hideImage(){
            // let j = document.getElementById('principal');
             document.getElementById('principal').style.display ="none";
            // j.remove();
        }
        function addImage(){
            document.getElementById('image-menu-x').style.display = "block";
        }
        function addImagePrincipal(){
         // let i =  document.getElementById('principal');
         document.body.classList.add('show-menu');
         console.log("call me")
        }

       function Football (){
           // addImage();

           var x = document.getElementById("id-menu");
           if (x.style.display === "none") {
               x.style.display = "block";
               document.body.classList.add("stop-scrolling");
               hideImage();
               addImage();
           } else {
               document.body.classList.remove("stop-scrolling");
               document.getElementById('image-menu-x').style.display = "none";
               document.getElementById('principal').style.display = "block";
               // addImagePrincipal();
               document.body.classList.add('show-menu');
               x.style.display = "none";
           }
           // removeImage();
        }

        const [navSize, setnavSize] = useState("10rem");
        const [navColor, setnavColor] = useState("transparent");
        const listenScrollEvent = () => {
            window.scrollY > 10 ? setnavColor("    rgba(1, 0, 0, 0.1) ") : setnavColor("transparent");
            window.scrollY > 10 ? setnavSize("5rem") : setnavSize("10rem");
        };
        useEffect(() => {
            window.addEventListener("scroll", listenScrollEvent);
            return () => {
                window.removeEventListener("scroll", listenScrollEvent);
            };
        }, []);


        return (
            <Router>
                <div>
                    <Navbar className="nav-menu"  expand="lg" style={{
                        backgroundColor: navColor,
                        height: navSize,
                        transition: "all 1s"
                    }}>
                        <Container className="">
                            <div className="link-nav numero" as={Link} to={"/"}>
                                <a href="tel:+258 84 710 6660">
                                    <img className="image-numero" src={require('../assets/logo/n1.png')}  alt=""/>
                                </a>
                                {/*&nbsp; &nbsp;PT / EN*/}
                                {/*{lngs.map((lng, i) => {*/}
                                {/*    const { code, native } = lng;*/}
                                {/*    return <button className="unstyled-button " onClick={() => handleTrans(code)}>{native}</button>;*/}
                                {/*})}*/}
                                &nbsp; &nbsp; <button className="active unstyled-button " onClick={() => changeLanguage('pt')}>PT</button>&nbsp; / &nbsp;
                                <button className="unstyled-button " onClick={() => changeLanguage('en')}>EN</button>


                            </div>
                                <div className="">
                                    <div onClick={() => { Football(); }}  id="menu1" className="link-nav direita">
                                        <img id="principal" className="image-menu show-menu" src={menu} alt=""/>
                                        <img id="image-menu-x" className="image-menu remo " src={menux} alt=""/>
                                    </div>
                                </div>
                        </Container>
                    </Navbar>
                </div>
                <div>
                    <Routes>
                        <Route path="/" >

                        </Route>
                    </Routes>
                </div>
            </Router>
        );
        // const root = ReactDOM.createRoot(document.getElementById('menu1'));
        // root.render(<Football />);
    }
// }

// export default NavbarComp;