import React from 'react';
import {Container} from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useTranslation} from "react-i18next";

function FooterComp (){
    const { t, i18n } = useTranslation();
    // function getYear() {
    //     return new Date().getFullYear();
    // }
    // render() {

        return (
            <div id="contact" className="footer">
                <Container>
                    <Row>
                        <Col>
                            <h1> {t('reserva')}</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                           <p>
                                <a href="tel:+258 84 710 6660">TEL.: +258 84 710 6660</a>   / geral@oceanmaputo.co.mz <br/>
                               Avenida Kim Il Sung, N 153, Maputo 1100 Moçambique
                           </p>
                            <p>{t('open')}<br/>

                            </p>
                            <p><a href="https://goo.gl/maps/6eDDVzUo9wYpke4t9" target="_blank"><u>{t('direcoes')}</u></a></p>
                            <p>
                                <a href="https://www.facebook.com/oceanmaputo" target="_blank">FACEBOOK</a>
                                &nbsp;| <a href="https://www.instagram.com/oceanmaputo/" target="_blank">INSTAGRAM</a>
                                &nbsp;| <a href="https://www.tripadvisor.pt/Restaurant_Review-g293819-d10744719-Reviews-Ocean_Maputo-Maputo_Maputo_Province.html"  >TRIPADVISOR</a>
                            </p>
                        </Col>


                    </Row>
                    <Row>
                        <hr/>
                        <Col>
                            <ul>
                                <li className="list-group-item"> Copyright  © {new Date().getFullYear()} OCEAN MAPUTO | {t('designed')}
                                    <a href="https://brandlovers.co.mz/" target="_blank" rel="noopener noreferrer"> Brandlovers Mz</a> | {t('direito')}
                                </li>
                            </ul>
                        </Col>
                    </Row>

                </Container>
            </div>

        );
    // }
}

export default FooterComp;