import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
    .use(initReactI18next)
    .init({
        debug: true,
        fallbackLng: 'pt',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        // language resources
        resources: {
            en: {
                translation: {
                    welcome: "Welcome to React",
                    ocean:"OCEAN is an all-embracing name, a noun that transports us through flavors, a word that reveals goals.  \n" +

                        "OCEAN is a path that points the direction, a boat that sails with certain coordinates, a project in search of new conquests, the face of a new look.\n" +

                        "OCEAN is a restaurant dedicated to promoting our coast and the best that comes from it. That elevates the country to the best of itself. A new viewpoint over a city, a mirage over a beach, several views over the sea.\n" +

                        "OCEAN is a symbol of vitality. A brand that is a statement, that aims to make a difference, with safety always leading the way. \n" +

                        "OCEAN is relaxation. To be carried away by the aromas and colors that invade our palate and make us vibrate with the internal explosion between forks and toasts!\n",
                    careful:"A careful \n" +
                        "and sustainable selection",
                    carefultext:"From the bench of Sofala to the Bay of Maputo, passing through Limpopo and Rovuma, OCEAN presents the best of our Mozambican coast a varied menu, of extreme quality and, above all, with a strong bet on the local product.\n" +
                        " Our product, the Mozambican product. \n" +
                        "Every day, very early, our Chef makes his selection, so that we can present in your palate the freshness, the quality that only OCEAN can offer its customers!",
                    indico:" An Ode to the Indic Ocean \n" +
                        "    at your table",
                    reserva: "Reservations & Contacts",
                    direcoes: "GET DIRECTIONS",
                    direito: "Rights Reserved",
                    designed:"Designed & Developed by",
                    open: "Open every day from 11:30 am to 10:30 pm",
                    concept:"CONCEPT",
                    pescaSustentavel:"Sustainable Fishing",
                    contact:"Contacts"

                }

            },
            pt: {
                translation: {
                    welcome: "Bem vindo",

                    ocean:"  É um nome que absorve tudo, um substantivo que nos transporta por sabores adentro, um vocábulo que revela objectivos.\n"+
                        "    É um caminho que aponta a direcção, um barco que navega com coordenadas certas, um projecto em busca de novas conquistas, o rosto de um novo olhar.\n" +
                        "    É um restaurante que se dedica a promover a nossa costa e o que de melhor provém dela. Que eleva o país ao melhor de si próprio. Um novo ponto de vista sobre uma cidade, uma miragem sobre uma praia, várias vistas sobre o mar.\n" +
                        "    É símbolo de vitalidade. Uma marca que é uma afirmação, que pretende fazer a diferença, com a segurança sempre a indicar o caminho.\n" +
                        "    É relaxamento. Deixar-se levar pelos aromas e cores que nos invadem o palato e nos fazem vibrar com a explosão interna entre garfadas e brindes!",
                    careful:"Uma selecção criteriosa \n" +
                        "     e sustentável ",
                    carefultext:" Do banco de Sofala à Baía de Maputo, passando por Limpopo e Rovuma, o OCEAN\n" +
                        "                                    apresenta o melhor da nossa consta moçambicana uma carta variada, de extrema\n" +
                        "                                    qualidade e, sobretudo, com a forte aposta no produto local. O nosso produto, o\n" +
                        "                                    produto Moçambicano. Todos os dias bem cedo, o nosso Chefe faz a sua selecção, para\n" +
                        "                                    que possamos apresentar no seu prato a frescura, a qualidade que só o OCEAN poderá\n" +
                        "                                    disponibilizar aos seus clientes!",
                    indico:" Ocean é  ode ao Oceano Índico\n" +
                            "    na sua mesa",
                    reserva:"Reservas & Contactos",
                    direcoes:"OBTER DIRECÇÕES",
                    direito:"Direitos Reservados",
                    designed:"Projetado e desenvolvido por",
                    open: "Aberto Todos os Dias das 11H30 às 22H30 ",
                    concept:"CONCEITO",
                    pescaSustentavel:"Pesca Sustentável",
                    contact:"Contactos"
                }
            },
        }
    });

export default i18n;