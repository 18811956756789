import './css/style.css';
import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import FooterComp from "./components/FooterComp";
import NavbarComp from "./components/NavbarComp";
import Home from "./components/Home";
import ReactDOM from 'react-dom'

const loader = document.querySelector('.loader');

// if you want to show the loader when React loads data again
const showLoader = () => loader.classList.remove('loader--hide');

const hideLoader = () => loader.classList.add('loader--hide');

class App extends React.Component {
    componentDidMount() {
        this.props.hideLoader();
    }
    render(){
        return (
            <div className="App">
                <NavbarComp/>


                <Home/>
                <FooterComp/>
            </div>
        );
    }

}
setTimeout(() =>
        // the show/hide functions are passed as props
        ReactDOM.render(
            <App
                hideLoader={hideLoader}
                showLoader={showLoader}
            />,
            document.getElementById('app')
        )
    , 1000);

export default App;
